var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var D5,Eqa,Fqa,Gqa,F5,G5,H5,Hqa,Iqa,K5,I5,J5,L5,M5,Jqa,Kqa,Lqa,N5,Mqa,Nqa,O5,Oqa,Pqa;D5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=D5($CLJS.J.g(a,b),c),$CLJS.td(c)?$CLJS.Ik.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Ik.g(a,b)}return a};
Eqa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.A(u);u=$CLJS.B(u);var v=$CLJS.fm(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Fb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Se.j(l,$CLJS.Fb(v),m)):f}(a,b,c,d)};Fqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Eqa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.E5=function(a){return $CLJS.HE.h($CLJS.OE(a))};
Gqa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.kQ,$CLJS.A(b))?$CLJS.JW(d,$CLJS.IW(c)):d;return Fqa(a,b,function(f){return $CLJS.Df(function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z);C=$CLJS.E.g($CLJS.E5(C),$CLJS.E5(c))?e:C;x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);return $CLJS.ee($CLJS.E.g($CLJS.E5(x),
$CLJS.E5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
F5=function(a,b,c,d){var e=$CLJS.IA(a,b);if($CLJS.n(e)){var f=$CLJS.E5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),$CLJS.id(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.eg.j($CLJS.Cf,$CLJS.Rk.h($CLJS.Qk.g($CLJS.ch([f]),$CLJS.E5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$E],null))&&$CLJS.Qe(function(l){return $CLJS.QY(l,$CLJS.wB)},e)?null:e;if($CLJS.y(e))return $CLJS.gM(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ON,$CLJS.CL],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.hi($CLJS.SE("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Ky,$CLJS.UW,$CLJS.CL,$CLJS.IA(a,b),$CLJS.VW,$CLJS.IA(a,$CLJS.sd(b)),$CLJS.OL,d,$CLJS.WW,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ON,$CLJS.$E],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.FX.v(a,$CLJS.sd(b),$CLJS.Ik,k):D5(a,b)}return a};
G5=function(a,b){var c=$CLJS.d4.g(a,b),d=$CLJS.lh(0,$CLJS.D(c));return $CLJS.gf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$R],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.sW],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$E],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kQ],null)],null),function(){return function k(f){return new $CLJS.ne(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Ad(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.F(c,x);$CLJS.$E.h(z)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,x,$CLJS.$E],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);m=$CLJS.F(c,u);if($CLJS.$E.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,u,$CLJS.$E],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ne(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,x,$CLJS.CL],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,u,$CLJS.CL],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
H5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.wG),k=$CLJS.J.g(e,$CLJS.FR),l=$CLJS.I(c,2,null);return $CLJS.A($CLJS.DH(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.A(m),d)&&$CLJS.E.g($CLJS.wG.h($CLJS.hd(m)),f)&&$CLJS.E.g($CLJS.FR.h($CLJS.hd(m)),k)&&$CLJS.E.g($CLJS.id(m),l)},$CLJS.dm($CLJS.Ss,$CLJS.$R.h($CLJS.PW(a,b)))))};
Hqa=function(a,b,c,d){c=H5(a,b,c);return $CLJS.n(c)?$CLJS.$W.l(a,b,$CLJS.FX,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$R,c,2,1],null),$CLJS.Qk.g(function(e){return $CLJS.A1($CLJS.Al,e)},$CLJS.hl),d])):a};Iqa=function(a,b,c){c=H5(a,b,c);return $CLJS.n(c)?$CLJS.$W.l(a,b,F5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$R],null),$CLJS.IA($CLJS.PW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$R,c],null)),b])):a};
K5=function(a,b,c,d,e,f){var k=$CLJS.$W.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.E5(e);return $CLJS.Ok.g(a,k)?$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(m,t){try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.he(u,$CLJS.kQ))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.IW(e);K=I5.W?I5.W(k,b,c,$CLJS.wB,$CLJS.N,K):I5.call(null,k,b,c,$CLJS.wB,$CLJS.N,K);return J5.v?J5.v(K,b,c,l):J5.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.F(t,0);if($CLJS.he(u,$CLJS.fG))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=I5.W?I5.W(k,b,c,$CLJS.fG,$CLJS.N,l):I5.call(null,k,b,c,$CLJS.fG,$CLJS.N,l);return J5.v?J5.v(S,b,c,l):J5.call(null,S,b,c,l)}()],null);throw $CLJS.Y;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.eG))return new $CLJS.P(null,
1,5,$CLJS.Q,[J5.v?J5.v(k,b,c,l):J5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.$E))return new $CLJS.P(null,1,5,$CLJS.Q,[J5.v?J5.v(k,b,c,l):J5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){var z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw v;else throw S;}else throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.he(C,$CLJS.ON))try{var G=$CLJS.F(t,2);if($CLJS.he(G,$CLJS.$E))return new $CLJS.P(null,1,5,$CLJS.Q,[J5.v?J5.v(k,b,c,l):J5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw z;}throw V;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Cf,d)))):k};
I5=function(a,b,c,d,e,f){var k=$CLJS.PW(a,b),l=$CLJS.jf(function(m){var t=$CLJS.IA(k,m);return $CLJS.n(t)?$CLJS.cm(function(u){return $CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function C(x,z){try{if($CLJS.zd(z)&&3===$CLJS.D(z))try{var G=$CLJS.F(z,0);if($CLJS.E.g(G,d))try{var K=$CLJS.F(z,1);if($CLJS.td(e)||$CLJS.z1($CLJS.fh(e),$CLJS.fh(K)))try{var S=$CLJS.F(z,2);if($CLJS.E.g(S,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var V=
Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)return $CLJS.KV(C,x,z);throw V;}throw Z;}}($CLJS.Cf,u))))},t):null},$CLJS.H([G5(a,b)]));return $CLJS.eb(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return K5(m,b,
c,u,t,function(v,x,z){return F5(v,x,z,b)})},a,l)};J5=function(a,b,c,d){b=$CLJS.G2(c,b);if($CLJS.n(b)){var e=$CLJS.PW(c,b);c=$CLJS.Re(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.YK),l=$CLJS.J.g(f,$CLJS.l1);return $CLJS.E.g($CLJS.HZ,k)&&$CLJS.E.g(d,l)?$CLJS.TZ.h(f):null},$CLJS.T0.j(c,b,e));return $CLJS.n(c)?I5(a,b,a,$CLJS.qG,$CLJS.N,c):a}return a};
L5=function(a,b,c,d,e){var f=$CLJS.BW.h(c),k=$CLJS.PW(a,b);c=$CLJS.DH(function(t){var u=$CLJS.IA(k,t);if($CLJS.n(u)){var v=$CLJS.E5(f);return $CLJS.n($CLJS.Re($CLJS.Qk.j($CLJS.ch([v]),$CLJS.HE,$CLJS.hd),u))?t:null}return null},G5(a,b));var l=(d=$CLJS.E.g($CLJS.OG,d))?$CLJS.BW.h(e):null;e=d?function(t,u,v){return Gqa(t,u,v,l)}:function(t,u,v){return F5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eG],null),c);a=d&&m&&$CLJS.E.g($CLJS.A(f),$CLJS.A(l))&&$CLJS.E.g($CLJS.id(f),$CLJS.id(l))?
Hqa(a,b,f,$CLJS.em($CLJS.hd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FR,$CLJS.wG],null))):m?Iqa(a,b,f):a;return $CLJS.n(c)?K5(a,b,a,c,f,e):a};M5=function(a,b){return $CLJS.QY(a,$CLJS.qG)&&$CLJS.E.g($CLJS.L1(a),b)};Jqa=function(a,b,c){return function k(e,f){try{if(M5(f,b))return $CLJS.J3(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.hX(k,e,f);throw l;}throw m;}}($CLJS.Cf,a)};
Kqa=function(a,b,c){var d=$CLJS.ON.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.IA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.dF],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Ok.g(e,c):b)){var f=$CLJS.SW();$CLJS.F3(f,$CLJS.hf.g($CLJS.dF,d));c=f(c);return Jqa($CLJS.gM(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,b,$CLJS.dF],null),c),e,c)}return a};
Lqa=function(a,b,c){if($CLJS.Fd(c))return c;var d=function(){var e=$CLJS.ch([c]);return"string"===typeof c?$CLJS.Qk.g(e,$CLJS.dF):e}();return $CLJS.Re(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.dm($CLJS.Ss,$CLJS.ON.h($CLJS.PW(a,b))))};
N5=function(a,b,c,d){b=$CLJS.nu.g($CLJS.fh($CLJS.T0.j(b,c,$CLJS.PW(b,c))),$CLJS.fh($CLJS.T0.j(a,c,$CLJS.PW(a,c))));return $CLJS.eb(function(e,f){return $CLJS.Se.N(I5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Mqa=function(a,b,c){a=N5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,new $CLJS.h(null,1,[$CLJS.EP,$CLJS.g_.h(d)],null),$CLJS.Zi.h(d)],null)});c=$CLJS.G2(a,c);return $CLJS.n(c)?N5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,$CLJS.N,$CLJS.TZ.h(d)],null)}):a};
Nqa=function(a,b,c){return $CLJS.Fd(c)?$CLJS.IA($CLJS.PW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,c,$CLJS.dF],null)):$CLJS.xd(c)?$CLJS.dF.h(c):c};O5=function(a,b,c,d){var e=Nqa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.$W)(a,b,function(k){var l=$CLJS.ON.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.NE(k,$CLJS.ON,l)});return(0,$CLJS.$W)(f,b,function(k){return $CLJS.dX(k,$CLJS.ON,function(l){return $CLJS.Sk.g(function(m){return $CLJS.R3(f,b,m)},l)})})}(),Mqa(c,a,b)):a};
Oqa=function(a,b){return null!=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function f(d,e){try{if(M5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Y)return $CLJS.KV(f,d,e);throw k;}throw l;}}($CLJS.Cf,a))))};Pqa=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.Q5=function(){function a(d,e,f){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.fu.h(f),$CLJS.uL)?$CLJS.P5.j?$CLJS.P5.j(d,e,f):$CLJS.P5.call(null,d,e,f):L5(d,e,f,Pqa,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.S5=function(){function a(d,e,f,k){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.fu.h(f),$CLJS.uL)?$CLJS.R5.v?$CLJS.R5.v(d,e,f,k):$CLJS.R5.call(null,d,e,f,k):L5(d,e,f,$CLJS.OG,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Qqa=function(){function a(d,e,f,k){f=Lqa(d,e,f);return $CLJS.n(f)?$CLJS.$W.l(d,e,Kqa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.P5=function(){function a(d,e,f){try{return O5(d,e,f,function(u,v){return $CLJS.Fe($CLJS.g1(function(x){return!($CLJS.E.g($CLJS.dF.h(x),v)||Oqa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.hm(k)),m=$CLJS.J.g(l,$CLJS.OL),t=$CLJS.J.g(l,$CLJS.Ky);l=$CLJS.J.g(l,$CLJS.VW);if($CLJS.E.g(t,$CLJS.UW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.R5=function(){function a(d,e,f,k){return null==k?$CLJS.P5.j(d,e,f):O5(d,e,f,function(l,m){return $CLJS.Sk.g(function(t){return $CLJS.E.g($CLJS.dF.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();