var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var LL,NL,PL,SL,XL;$CLJS.HL=function(a){return $CLJS.Wh($CLJS.q($CLJS.oC),a,$CLJS.uG)};$CLJS.IL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.JL=new $CLJS.M(null,"row","row",-570139521);$CLJS.KL=new $CLJS.M(null,"object-id","object-id",-754527291);LL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.ML=new $CLJS.M(null,"operators","operators",-2064102509);
NL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.OL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);PL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.QL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.RL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
SL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.TL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.UL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.VL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.WL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
XL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.YL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(NL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,$CLJS.FE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.mH],null)],null)],null));$CLJS.X(XL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,NL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,$CLJS.JE],null)],null)],null));$CLJS.X(PL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.qG],null),XL,$CLJS.AE],null));
$CLJS.X(SL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.qG],null),NL,$CLJS.qL],null));
$CLJS.QG.g($CLJS.qG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.qG],null),NL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.qL,$CLJS.AE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.Oi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.mC(a)):null},$CLJS.yt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.qB,SL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,PL],null)],null)],null));$CLJS.yF($CLJS.qG,$CLJS.uG);$CLJS.UE.m(null,$CLJS.qG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Fi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.gF});$CLJS.FF($CLJS.wB,$CLJS.H([$CLJS.AE]));$CLJS.UE.m(null,$CLJS.wB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Fi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.gF});
$CLJS.yF($CLJS.wB,$CLJS.uG);$CLJS.X(LL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,$CLJS.FE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.AE],null)],null)],null));$CLJS.QG.g($CLJS.fG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.fG],null),LL,$CLJS.Yj],null));
$CLJS.UE.m(null,$CLJS.fG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Fi,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.gF});$CLJS.yF($CLJS.fG,$CLJS.uG);$CLJS.FF($CLJS.PF,$CLJS.H([$CLJS.rt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null)],null)]));$CLJS.yF($CLJS.PF,$CLJS.uG);
$CLJS.FF($CLJS.lG,$CLJS.H([$CLJS.rt,$CLJS.gF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null)]));$CLJS.yF($CLJS.lG,$CLJS.uG);
$CLJS.X($CLJS.uG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,$CLJS.WE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.At,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Jt(", ",$CLJS.bH($CLJS.Jd,$CLJS.ll.g($CLJS.q($CLJS.oC),$CLJS.uG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.HL(a)}],null)],null));