var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var CW,Yka,Zka,KW,$ka,ala,cla,GW,dla,bla;CW=function(a){return a+1};$CLJS.DW=function(a){if("string"===typeof a){var b=$CLJS.nh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.jm(a));};
Yka=function(a){var b=new $CLJS.Ea,c=$CLJS.Fu;$CLJS.Fu=new $CLJS.sc(b);try{var d=$CLJS.Fu,e=$CLJS.Va($CLJS.Nv(d)),f=$CLJS.Fu;$CLJS.Fu=e?$CLJS.Ev(d):d;try{d=$CLJS.Pv;$CLJS.Pv=!0;try{$CLJS.Rv(a)}finally{$CLJS.Pv=d}$CLJS.E.g(0,$CLJS.Mu($CLJS.Fu,$CLJS.Ou))||$CLJS.ac($CLJS.Fu,"\n");$CLJS.Lu()}finally{$CLJS.Fu=f}$CLJS.uh($CLJS.p.h(b))}finally{$CLJS.Fu=c}};$CLJS.EW=function(a,b){return $CLJS.Se.j($CLJS.fa,a,b)};
$CLJS.FW=function(a){var b=$CLJS.Bv;$CLJS.Bv=120;try{var c=new $CLJS.Ea,d=$CLJS.Qa,e=$CLJS.Ra;$CLJS.Qa=!0;$CLJS.Ra=function(f){return c.append(f)};try{Yka(a)}finally{$CLJS.Ra=e,$CLJS.Qa=d}return $CLJS.p.h(c)}finally{$CLJS.Bv=b}};
Zka=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.OV,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.LV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Xe($CLJS.N),e=$CLJS.Xe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var z=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(z):C.call(null,z)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Oh.v(e,$CLJS.Tk,u,$CLJS.bE(CW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Oh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var z=t,C=$CLJS.p.h(x);return c.g?c.g(z,C):c.call(null,z,C)}()}}function k(m){var t=l,u=t.g;null==GW&&(GW=$CLJS.Xe(0));var v=$CLJS.Fh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Oh.g(GW,CW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.HW=function(a){return $CLJS.zd(a)&&1<$CLJS.D(a)&&$CLJS.A(a)instanceof $CLJS.M&&$CLJS.xd($CLJS.hd(a))&&$CLJS.Id($CLJS.hd(a),$CLJS.HE)};$CLJS.IW=function(a){return $CLJS.HW(a)?$CLJS.zE.h($CLJS.OE(a)):null};$CLJS.JW=function(a,b){return $CLJS.PE($CLJS.HW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,2,[$CLJS.HE,$CLJS.p.h($CLJS.RE()),$CLJS.Fi,$CLJS.VE(a)],null),a],null),$CLJS.R,$CLJS.H([$CLJS.zE,b]))};
KW=function(a,b,c){var d=$CLJS.Id(a,b)?$CLJS.Tk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.E.g($CLJS.A(e),$CLJS.Gs)?$CLJS.Df($CLJS.bf(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Id(a,b)?$CLJS.JV(d,$CLJS.Ee([b,c])):d};$ka=function(a){var b=$CLJS.em(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pP,$CLJS.TQ],null));b=LW.h?LW.h(b):LW.call(null,b);return $CLJS.zW($CLJS.R.l(KW($CLJS.Ik.l(a,$CLJS.pP,$CLJS.H([$CLJS.TQ])),$CLJS.bS,$CLJS.CL),$CLJS.fu,$CLJS.uL,$CLJS.H([$CLJS.zL,b])))};
ala=function(a){return $CLJS.Sk.g($ka,a)};cla=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Tk.j($CLJS.Ml(a)?new $CLJS.h(null,1,[$CLJS.MW,a],null):a,$CLJS.MW,function(b){return $CLJS.Sk.g(function(c){return $CLJS.R.j($CLJS.JA(c,$CLJS.jB),$CLJS.fu,$CLJS.WK)},b)}),$CLJS.fu,bla):null};
$CLJS.NW=function(a){if($CLJS.E.g($CLJS.fu.h(a),$CLJS.rW))return a;var b=$CLJS.mj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.fu,$CLJS.rW,$CLJS.zL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.fu,$CLJS.lW],null),$CLJS.JV($CLJS.uQ.h(a),new $CLJS.h(null,1,[$CLJS.NF,$CLJS.uQ],null))]))],null)],null),$CLJS.Ik.l(a,$CLJS.mj,$CLJS.H([$CLJS.uQ]))]));case "query":return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.fu,$CLJS.rW,$CLJS.zL,LW($CLJS.NF.h(a))],null),$CLJS.Ik.l(a,$CLJS.mj,$CLJS.H([$CLJS.NF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.OW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.zL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.hi($CLJS.qE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[dla,$CLJS.D(a)],null));return c};
$CLJS.PW=function(a,b){a=$CLJS.NW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.zL);return $CLJS.J.g($CLJS.Df(c),$CLJS.OW(a,b))};$CLJS.QW=function(a){return"string"===typeof a&&(a=$CLJS.oh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.DW(a)):null};$CLJS.SW=function(){return $CLJS.Qk.g(RW,Zka($CLJS.H([$CLJS.OV,$CLJS.fB,$CLJS.LV,function(a,b){return RW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};GW=null;
$CLJS.TW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.UW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.VW=new $CLJS.M(null,"join","join",-758861890);$CLJS.WW=new $CLJS.M(null,"stage","stage",1843544772);dla=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.MW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.XW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.YW=new $CLJS.M(null,"table","table",-564943036);bla=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.ZW=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var LW,RW;
LW=function LW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.TQ),d=$CLJS.J.g(a,$CLJS.bP);c=$CLJS.n(c)?LW.h?LW.h(c):LW.call(null,c):$CLJS.Cf;d=cla(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.gM(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.yV],null),d):c;d=$CLJS.n($CLJS.uQ.h(a))?$CLJS.lW:$CLJS.eW;a=$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.fu,d],null),$CLJS.Ik.l(a,$CLJS.TQ,$CLJS.H([$CLJS.bP]))]));a=$CLJS.y($CLJS.ON.h(a))?$CLJS.Tk.j(a,$CLJS.ON,ala):a;a=KW(a,$CLJS.VF,$CLJS.sW);return $CLJS.be.g(c,a)};
$CLJS.$W=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.NW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.zL);d=$CLJS.OW(c,d);e=$CLJS.Se.N($CLJS.Tk,$CLJS.Df(k),d,e,f);return $CLJS.R.j(c,$CLJS.zL,e)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
RW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.xW.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.aX=RegExp(" id$","i");