var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var xna,yna,zna,Ana,Bna,d1;
$CLJS.a1=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.GA(e);var k=$CLJS.zh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.IV("Default period","Default periods",e);case "millisecond":return $CLJS.IV("Millisecond","Milliseconds",e);case "second":return $CLJS.IV("Second","Seconds",e);case "minute":return $CLJS.IV("Minute","Minutes",e);case "hour":return $CLJS.IV("Hour","Hours",e);case "day":return $CLJS.IV("Day","Days",e);case "week":return $CLJS.IV("Week","Weeks",
e);case "month":return $CLJS.IV("Month","Months",e);case "quarter":return $CLJS.IV("Quarter","Quarters",e);case "year":return $CLJS.IV("Year","Years",e);case "minute-of-hour":return $CLJS.IV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.IV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.IV("Day of week","Days of week",e);case "day-of-month":return $CLJS.IV("Day of month","Days of month",e);case "day-of-year":return $CLJS.IV("Day of year","Days of year",e);case "week-of-year":return $CLJS.IV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.IV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.IV("Quarter of year","Quarters of year",e);default:return f=$CLJS.Yt($CLJS.gh(f),/-/),e=$CLJS.y(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.Jt(" ",$CLJS.ee($CLJS.DA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.b1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.mC(f)},e,a,b,c,d)}();xna=$CLJS.Xe($CLJS.N);yna=$CLJS.Xe($CLJS.N);
zna=$CLJS.Xe($CLJS.N);Ana=$CLJS.Xe($CLJS.N);Bna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.c1=new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.mC,Bna,xna,yna,zna,Ana);$CLJS.c1.m(null,$CLJS.ci,function(){return null});$CLJS.c1.m(null,$CLJS.lH,function(a){return $CLJS.si.h(a)});d1=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.cH,null,$CLJS.fH,null,$CLJS.gH,null,$CLJS.dH,null],null),null);
$CLJS.Cna=$CLJS.eg.j($CLJS.Cf,$CLJS.Qk.g($CLJS.Rk.h(d1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.fu,$CLJS.lH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.rk)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.xH);$CLJS.Dna=$CLJS.Sk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.fu,$CLJS.lH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.gu)?$CLJS.R.j(b,$CLJS.ci,!0):b},$CLJS.sH);
$CLJS.Ena=$CLJS.eg.j($CLJS.Cf,$CLJS.Qk.g($CLJS.Rk.h(d1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.fu,$CLJS.lH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.gu)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.yH);$CLJS.I0.m(null,$CLJS.lH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.si);return $CLJS.a1.h(a)});
$CLJS.P0.m(null,$CLJS.lH,function(a,b,c){return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.vE,$CLJS.i0.j(a,b,c),$CLJS.G0,$CLJS.eB($CLJS.c1.h(c))],null),$CLJS.em(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ci,$CLJS.q0],null))]))});
$CLJS.e1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.mC(l)},e,a,b,c,d)}();$CLJS.e1.m(null,$CLJS.ci,function(){return $CLJS.bh});
$CLJS.f1=function(){function a(d,e,f){return $CLJS.e1.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();