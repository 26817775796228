var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var dna,W_,ena,Y_,Z_,$_,a0,u0,hna,gna,ina,fna,A0,m0,C0,jna,o0;dna=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};W_=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Id($CLJS.Zda,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
ena=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.BB,!0],null)),c=$CLJS.J.g(b,$CLJS.Yda),d=$CLJS.J.g(b,$CLJS.BB);return $CLJS.y(a)?(b=$CLJS.GV(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=W_(x,$CLJS.Va(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee(W_(u,
$CLJS.Va(d)),k($CLJS.Lc(l)))}return null}},null,null)}(dna($CLJS.fB(a)))}()),$CLJS.n(c)?$CLJS.GV($CLJS.af(c,b)):b):null};$CLJS.X_=function(a){return $CLJS.pP.h($CLJS.A($CLJS.zL.h(a)))};Y_=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=Y_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Y_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.table",a);}return a};
Z_=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=Z_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Z_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.card",a);}return a};$_=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=$_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=$_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.metric",a);}return a};
a0=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=a0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=a0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.fields",a);}return a};$CLJS.b0=function(a){return $CLJS.FZ(a)?a:$CLJS.rE.h(a)};$CLJS.c0=function(a,b){return Y_($CLJS.b0(a),b)};$CLJS.d0=function(a,b){return a0($CLJS.b0(a),b)};$CLJS.e0=function(a,b){return Z_($CLJS.b0(a),b)};$CLJS.f0=function(a,b){return $_($CLJS.b0(a),b)};
$CLJS.g0=function(a,b,c){if($CLJS.Xa(b)||$CLJS.xd(b)){$CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.Xe($CLJS.N));var d=b.__mbcache;if($CLJS.n(d)){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.Oh.v(d,$CLJS.R,a,b);return b}}return c.h?c.h(b):c.call(null,b)};
u0=function(a,b,c){var d=$CLJS.h0.j(a,b,c);return $CLJS.hl.l($CLJS.H([$CLJS.em(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.vE,$CLJS.Uj],null)),function(){var e=$CLJS.i0.v(a,b,c,$CLJS.j0);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.k0,e],null):null}(),function(){var e=$CLJS.HA($CLJS.Fi,$CLJS.zB)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Fi,e],null):null}(),function(){var e=$CLJS.kL.h(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.YW,$CLJS.l0.j(a,b,$CLJS.Fd(e)?$CLJS.c0(a,e):"string"===typeof e?
$CLJS.e0(a,$CLJS.QW(e)):null)],null):null}(),function(){var e=$CLJS.YK.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[m0,$CLJS.E.g(e,$CLJS.HZ),$CLJS.n0,$CLJS.E.g(e,$CLJS.QZ),o0,$CLJS.E.g(e,$CLJS.NZ),$CLJS.p0,$CLJS.E.g(e,$CLJS.PZ),fna,$CLJS.E.g(e,$CLJS.sL),gna,$CLJS.E.g(e,$CLJS.mL)],null):null}(),function(){var e=$CLJS.UZ.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.q0,e],null)}(),$CLJS.em(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.r0,$CLJS.s0,$CLJS.t0],null))]))};
$CLJS.v0=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.t0=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.w0=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.p0=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.r0=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.x0=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);
$CLJS.q0=new $CLJS.M(null,"selected","selected",574897764);hna=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);gna=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);ina=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.y0=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);
$CLJS.z0=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);fna=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);A0=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.B0=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);m0=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);
C0=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);$CLJS.D0=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.n0=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.E0=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.s0=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);jna=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.k0=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.F0=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.j0=new $CLJS.M(null,"long","long",-171452093);$CLJS.G0=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.H0=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);o0=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.I0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.mC(l)},e,a,b,c,d)}();
$CLJS.J0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.mC(l)},e,a,b,c,d)}();
$CLJS.i0=function(){function a(f,k,l,m){var t=$CLJS.vE.h($CLJS.OE(l));if($CLJS.n(t))return t;try{return $CLJS.I0.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.gi($CLJS.qE("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([l])),$CLJS.DV(k)])),new $CLJS.h(null,2,[$CLJS.NF,f,$CLJS.IE,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.ci):e.call(null,f,k,l,$CLJS.ci)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.K0=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.OE(f));if($CLJS.n(k))return k;try{return $CLJS.J0.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.gi($CLJS.qE("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f])),$CLJS.DV(k)])),new $CLJS.h(null,3,[$CLJS.IE,f,$CLJS.NF,d,$CLJS.OL,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.I0.m(null,$CLJS.ci,function(a,b,c){a=$CLJS.RA($CLJS.My);$CLJS.n($CLJS.QA("metabase.lib.metadata.calculation",a))&&$CLJS.PA("metabase.lib.metadata.calculation",a,$CLJS.EW("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.Mh.l($CLJS.H([c])),ina,$CLJS.mC(c)])),null);return $CLJS.zd(c)&&$CLJS.A(c)instanceof $CLJS.M?$CLJS.gh($CLJS.A(c)):$CLJS.Mh.l($CLJS.H([c]))});
$CLJS.J0.m(null,$CLJS.ci,function(a,b,c){return ena($CLJS.LA($CLJS.i0.j(a,b,c),/[\(\)]/,""))});$CLJS.L0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.kna=function(){function a(d,e,f){return $CLJS.L0.j(d,e,$CLJS.zh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.M0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.mC(l)},e,a,b,c,d)}();
$CLJS.N0=function(){function a(d,e,f){var k=$CLJS.OE(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.wG),m=$CLJS.Fi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Id($CLJS.AH,l):l)?$CLJS.wj:null;if($CLJS.n(l))return l;k=$CLJS.zB.h(k);if($CLJS.n(k))return k;d=$CLJS.M0.j(d,e,f);return $CLJS.AC(d,$CLJS.Aj)?d:$CLJS.Aj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.M0.m(null,$CLJS.ci,function(a,b,c){return $CLJS.VE(c)});$CLJS.M0.m(null,$CLJS.kF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.N0.j(a,b,c)});$CLJS.M0.m(null,$CLJS.eJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.VE(c):$CLJS.N0.j(a,b,d)});
$CLJS.O0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.mC(l)},e,a,b,c,d)}();
$CLJS.O0.m(null,$CLJS.ci,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.fu,$CLJS.WK,$CLJS.zB,$CLJS.N0.j(a,b,c),$CLJS.T,$CLJS.K0.j(a,b,c),$CLJS.vE,$CLJS.i0.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.gi($CLJS.qE("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([$CLJS.mC(c)])),$CLJS.DV(d)])),new $CLJS.h(null,3,[$CLJS.NF,a,$CLJS.OL,b,$CLJS.IE,c],null),d);}throw e;}});
$CLJS.h0=function(){function a(e,f,k){return $CLJS.O0.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.P0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.mC(l)},e,a,b,c,d)}();
$CLJS.X(A0,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vE,$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.k0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YW,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,A0],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[m0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.n0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[o0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.p0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[C0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[jna,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[hna,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Yj],null),
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.St,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.G0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.E0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.q0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.w0,
new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,$CLJS.nG,$CLJS.gG],null)],null)],null));
$CLJS.l0=function(){function a(d,e,f){return $CLJS.g0($CLJS.zh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.P0.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.gi($CLJS.qE("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.mC(k),$CLJS.DV(l)])),new $CLJS.h(null,3,[$CLJS.NF,d,$CLJS.OL,e,$CLJS.IE,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.P0.m(null,$CLJS.ci,function(a,b,c){return u0(a,b,c)});$CLJS.P0.m(null,$CLJS.cL,function(a,b,c){return $CLJS.hl.l($CLJS.H([u0(a,b,c),new $CLJS.h(null,1,[C0,$CLJS.E.g($CLJS.X_(a),$CLJS.Zi.h(c))],null)]))});
$CLJS.Q0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.mC(l)},e,a,b,c,d)}();$CLJS.Q0.m(null,$CLJS.CB,function(){return $CLJS.Cf});
$CLJS.R0=function(){function a(f,k,l,m){m=$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.D0,$CLJS.SW()],null),m]));return $CLJS.Q0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.PW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.S0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.oC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.mC(l)},e,a,b,c,d)}();$CLJS.S0.m(null,$CLJS.CB,function(){return $CLJS.Cf});
$CLJS.S0.m(null,$CLJS.ci,function(a,b,c,d){return $CLJS.Q0.v(a,b,c,d)});
$CLJS.T0=function(){function a(f,k,l,m){m=$CLJS.hl.l($CLJS.H([$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.D0,$CLJS.SW()],null),new $CLJS.h(null,4,[$CLJS.B0,!0,$CLJS.H0,!0,$CLJS.x0,!0,$CLJS.F0,!0],null)])),m]));return $CLJS.S0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.PW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.i0};