var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var qqa=function(a,b){return new $CLJS.ne(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.A(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Lc(d);else break a}return d},null,null)},rqa=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cu(a,b),qqa(a,b)],null)},i5=function(a){var b=$CLJS.hl.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.b5);c=$CLJS.J.g(c,$CLJS.c5);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.X4.h;d=$CLJS.zh.h(d);var e=$CLJS.q($CLJS.U4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.X4,d);c=new $CLJS.h(null,2,[$CLJS.a5,c,$CLJS.d5,c],null)}else c=new $CLJS.h(null,1,[$CLJS.d5,2],null);b=b.call($CLJS.hl,$CLJS.H([c,a]));return $CLJS.n($CLJS.e5.h(a))?$CLJS.R.l($CLJS.Ik.g(b,$CLJS.e5),$CLJS.d5,$CLJS.e5.h(a),$CLJS.H([$CLJS.a5,$CLJS.e5.h(a)])):b},j5=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=j5[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=j5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("NumberFormatter.format-number-basic",
a);}return a},sqa=function(a,b){var c=$CLJS.Uz(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.zi,!0],null)]));a=$CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.mj.h(d),"exponentMinusSign")},c))?c:function(){var d=rqa(function(f){return $CLJS.Ok.g($CLJS.mj.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.gf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.mj,"exponentPlusSign",$CLJS.Dj,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.Se.g($CLJS.p,
$CLJS.hf.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.mj);e=$CLJS.J.g(e,$CLJS.Dj);switch(d){case "exponentSeparator":return"e";default:return e}},a))},tqa=function(a,b){switch($CLJS.c5.h(b)){case "scientific":return function(c){return sqa(a,c)};default:return function(c){return a.format(c)}}},k5=function(a,b,c,d,e){this.options=a;this.Ag=b;this.Dg=c;this.ee=d;this.ii=e;this.C=393216;this.I=0},uqa=function(a,b){return $CLJS.Se.g($CLJS.p,function(){return function e(d){return new $CLJS.ne(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.kd(k,v),z=$CLJS.O(x);x=$CLJS.J.g(z,$CLJS.mj);z=$CLJS.J.g(z,$CLJS.Dj);var C=new $CLJS.ah(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:z,m.add(x));v+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.mj);t=$CLJS.J.g(t,$CLJS.Dj);if($CLJS.n(function(){var v=new $CLJS.ah(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ee($CLJS.E.g(u,"integer")?b:t,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}($CLJS.Uz(a.Ag.formatToParts(1),$CLJS.H([$CLJS.zi,!0])))}())},l5=function(a){var b=$CLJS.E.g($CLJS.c5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.kl($CLJS.OA($CLJS.Wa,$CLJS.Ig([$CLJS.nqa,
$CLJS.fqa,$CLJS.iqa,$CLJS.kqa,$CLJS.pqa,$CLJS.b5,$CLJS.jqa,$CLJS.lqa,$CLJS.gqa,$CLJS.hqa],[$CLJS.d5.g(a,b),$CLJS.g5.h(a),$CLJS.f5.h(a),$CLJS.mqa.h(a),$CLJS.E.g($CLJS.c5.h(a),"scientific")?null:$CLJS.c5.g(a,"decimal"),$CLJS.b5.h(a),!0,$CLJS.a5.g(a,b),$CLJS.E.g($CLJS.c5.h(a),"scientific")?"scientific":null,$CLJS.h5.h(a)]))));var c=$CLJS.g5.h(a);var d=$CLJS.b5.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=tqa(b,a);return new k5(a,b,c,d,$CLJS.N)},m5=function(a,b){return l5(i5(b)).Oe(null,a)},o5=
function(a,b){var c=$CLJS.Ik.l(b,$CLJS.HB,$CLJS.H([$CLJS.c5])),d=$CLJS.GA(a);if(0===a)return"0";if(1E3>d)return $CLJS.n5.g?$CLJS.n5.g(a,c):$CLJS.n5.call(null,a,c);b=$CLJS.A($CLJS.lf(function(f){return d>=$CLJS.A(f)},vqa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.hl.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.a5,1,$CLJS.d5,1],null)]));return $CLJS.n5.g?$CLJS.n5.g(f,k):$CLJS.n5.call(null,f,k)}()),$CLJS.p.h(b)].join("")},wqa=function(a,b){var c=i5(b);if($CLJS.n(p5.h(c)))b=
p5.h(c);else if($CLJS.Va($CLJS.e5.h(c))&&$CLJS.Va($CLJS.a5.h(c))&&$CLJS.Ok.g($CLJS.c5.h(c),"currency")&&$CLJS.GA(a)<($CLJS.E.g($CLJS.c5.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Ik.g(c,$CLJS.d5);c=$CLJS.h5.g(c,0);b=l5(b.call($CLJS.R,d,$CLJS.f5,2>c?2:c))}else b=l5(c);return j5(b,a)};k5.prototype.P=function(a,b){return new k5(this.options,this.Ag,this.Dg,this.ee,b)};k5.prototype.O=function(){return this.ii};
k5.prototype.Oe=function(a,b){a=this.ee.h?this.ee.h(b):this.ee.call(null,b);var c=$CLJS.oqa.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Ok.g(c,".,"):c)&&(b=$CLJS.A(c),c=$CLJS.hd(c),a=$CLJS.LA(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Dg)&&(b=$CLJS.b5.h(this.options),c=$CLJS.V4(b),a=$CLJS.LA($CLJS.LA(a,[$CLJS.gh(b)," "].join(""),c),$CLJS.gh(b),c));return a};
var p5=new $CLJS.M(null,"number-formatter","number-formatter",929788393),q5=new $CLJS.M(null,"scale","scale",-230427353),r5=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),xqa=new $CLJS.M(null,"scientific","scientific",316285837);var s5=new $CLJS.h(null,1,[$CLJS.g5,"symbol"],null),yqa=$CLJS.kl(s5),vqa=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),t5=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.c5)},e,a,b,c,d)}();t5.m(null,$CLJS.ci,function(a,b){return o5(a,b)});t5.m(null,"percent",function(a,b){return[$CLJS.p.h(o5(100*a,b)),"%"].join("")});t5.m(null,"currency",function(a,b){b=$CLJS.hl.l($CLJS.H([b,s5]));var c=l5(b);return 1E3>$CLJS.GA(a)?c.Oe(null,a):uqa(c,o5(a,b))});t5.m(null,"scientific",function(a,b){return m5(a,$CLJS.hl.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.d5,1,$CLJS.a5,1],null)])))});
$CLJS.n5=function n5(a,b){b=$CLJS.kB(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.HB);var e=$CLJS.J.g(d,r5),f=$CLJS.J.g(d,$CLJS.c5),k=$CLJS.J.g(d,q5);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Ik.g(d,q5),n5.g?n5.g(b,f):n5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,r5,!1);return n5.g?n5.g(l,m):n5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?t5.g(a,i5($CLJS.Ik.g(d,$CLJS.HB))):$CLJS.E.g($CLJS.zh.h(f),xqa)?m5(a,d):wqa(a,d)};
module.exports={compact_currency_options_js:yqa,format_number:$CLJS.n5};