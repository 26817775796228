var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var cfa,dfa,efa,ffa,gfa,hfa,ifa,WG,jfa,kfa,lfa,mfa,nfa,ofa,pfa,qfa,YG,rfa;$CLJS.RG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);cfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.SG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);dfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
efa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.TG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.UG=new $CLJS.M(null,"column-name","column-name",551523580);ffa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.VG=new $CLJS.M(null,"display-info","display-info",-816930907);gfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
hfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);ifa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);WG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);jfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);kfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);lfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
mfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);nfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);ofa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);pfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.XG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);qfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
YG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.ZG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);rfa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.HF($CLJS.Zx,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null)],null)]));$CLJS.HF($CLJS.rG,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null)],null)]));
$CLJS.FF($CLJS.kG,$CLJS.H([$CLJS.rt,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.FF($CLJS.WF,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));$CLJS.FF($CLJS.zG,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));
$CLJS.FF($CLJS.ik,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null)]));$CLJS.yF($CLJS.ik,$CLJS.kF);$CLJS.FF($CLJS.EG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.yF($CLJS.EG,$CLJS.kF);$CLJS.FF($CLJS.Zn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null)]));$CLJS.yF($CLJS.Zn,$CLJS.kF);
$CLJS.X(WG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,1,[$CLJS.yt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.yt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.FF($CLJS.bG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WG],null)]));$CLJS.yF($CLJS.bG,$CLJS.kF);
$CLJS.FF($CLJS.xG,$CLJS.H([$CLJS.rt,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));$CLJS.FF($CLJS.FG,$CLJS.H([$CLJS.rt,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.FF($CLJS.DG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));
$CLJS.FF($CLJS.MG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.yF($CLJS.DG,$CLJS.kF);$CLJS.yF($CLJS.MG,$CLJS.kF);$CLJS.FF($CLJS.KG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));$CLJS.yF($CLJS.KG,$CLJS.kF);
$CLJS.FF($CLJS.XF,$CLJS.H([$CLJS.rt,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.X(YG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.zs,qfa,ofa,hfa,dfa,cfa,gfa,ifa,jfa,kfa,nfa,lfa,rfa,pfa,efa,mfa,$CLJS.Dl],null));$CLJS.X($CLJS.SG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YG],null)],null));
$CLJS.$G=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.jG,$CLJS.Zx,$CLJS.TG,!1,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Count of rows"),$CLJS.UG,$CLJS.SE("Count"),$CLJS.St,$CLJS.SE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.DG,$CLJS.RG,$CLJS.ek,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Sum of ..."),$CLJS.UG,$CLJS.SE("Sum"),$CLJS.St,$CLJS.SE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.kG,$CLJS.RG,$CLJS.ek,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Average of ..."),$CLJS.UG,$CLJS.SE("Average"),$CLJS.St,$CLJS.SE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.EG,$CLJS.RG,$CLJS.ek,$CLJS.TG,!0,$CLJS.XG,$CLJS.mG,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Median of ..."),$CLJS.UG,$CLJS.SE("Median"),$CLJS.St,$CLJS.SE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.WF,$CLJS.RG,$CLJS.li,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Number of distinct values of ..."),$CLJS.UG,$CLJS.SE("Distinct values"),$CLJS.St,$CLJS.SE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.MG,$CLJS.RG,$CLJS.ek,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Cumulative sum of ..."),
$CLJS.UG,$CLJS.SE("Sum"),$CLJS.St,$CLJS.SE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.jG,$CLJS.rG,$CLJS.TG,!1,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Cumulative count of rows"),$CLJS.UG,$CLJS.SE("Count"),$CLJS.St,$CLJS.SE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.FG,$CLJS.RG,$CLJS.ek,$CLJS.TG,
!0,$CLJS.XG,$CLJS.AG,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Standard deviation of ..."),$CLJS.UG,$CLJS.SE("SD"),$CLJS.St,$CLJS.SE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.Zn,$CLJS.RG,$CLJS.Yi,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Minimum of ..."),$CLJS.UG,$CLJS.SE("Min"),$CLJS.St,$CLJS.SE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.ik,$CLJS.RG,$CLJS.Yi,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.vE,$CLJS.SE("Maximum of ..."),$CLJS.UG,$CLJS.SE("Max"),$CLJS.St,$CLJS.SE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ffa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.ZG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mk],null),$CLJS.hf.h($CLJS.jG),$CLJS.$G)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Xi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TG,$CLJS.Ps],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.XG,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VG,$CLJS.md],null)],null));